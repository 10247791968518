import {positionStore} from './PositionStore';
import {accountStore} from './AccountStore';
import {mtGroupStore} from '../fetchers/GroupStore';
import {makeAutoObservable} from 'mobx';

export enum MarginFreeMode {
  FREE_MARGIN_NOT_USE_PL,
  FREE_MARGIN_USE_PL,
  FREE_MARGIN_PROFIT,
  FREE_MARGIN_LOSS,
}

export class BalanceStore {
  get mode() {
    return mtGroupStore?.data?.MarginFreeMode || MarginFreeMode.FREE_MARGIN_USE_PL;
  }
  get PnLPercentage() {
    return (this.Pnl / Number(accountStore.EquityPrevDay)) * 100;
  }

  get Health() {
    let health = (Number(this.CalculatedEquity) / positionStore.State.Invested) * 100;
    if (Number.isFinite(health) === false || !health) {
      health = 0;
    }

    return health;
  }

  get CalculatedProfit() {
    return Number(accountStore.Storage) + positionStore.State.Profits;
  }

  get Pnl() {
    return this.CalculatedEquity - Number(accountStore.EquityPrevDay);
  }

  get CalculatedEquity() {
    return (
      Number(accountStore.Credit) +
      Number(accountStore.Balance) +
      (positionStore.State.Profits || Number(accountStore.Profit))
    );
  }

  get Avaliable() {
    const invested = positionStore.State.Invested;
    // alert(this.mode);
    switch (Number(this.mode)) {
      case MarginFreeMode.FREE_MARGIN_NOT_USE_PL:
        return Number(accountStore.Credit) + Number(accountStore.Balance) - invested;

      case MarginFreeMode.FREE_MARGIN_PROFIT: {
        const balance =
          Number(accountStore.Credit) + Number(accountStore.Balance) + Number(accountStore.Storage);
        return balance + positionStore.ProfitablePositions;
      }

      case MarginFreeMode.FREE_MARGIN_LOSS: {
        const balance =
          Number(accountStore.Credit) + Number(accountStore.Balance) + Number(accountStore.Storage);
        return balance + positionStore.LosingPositions;
      }

      case MarginFreeMode.FREE_MARGIN_USE_PL:
      default:
        return this.CalculatedEquity - invested;
    }
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export const balanceStore = new BalanceStore();
