import {symbolStore} from '../SymbolStore';
import {makeAutoObservable} from 'mobx';
import {captureException} from '@sentry/react';
import type {SymbolInfo} from './SymbolInfo';

export class ExchangeRate {
  cur1: string;
  cur2: string;
  digits = 5;
  symbol!: SymbolInfo;
  flip = false;
  notNeeded = false;
  constructor(sourceSymbol: SymbolInfo, cur: 'Profit' | 'Margin') {
    if (!sourceSymbol?.data) {
      captureException('source symbol does not exist', {
        tags: {source: 'ExchangeRate', sourceSymbol: JSON.stringify(sourceSymbol)},
      });
    }
    this.cur1 = 'USD';
    this.cur2 = sourceSymbol?.data[('Currency' + cur) as 'CurrencyMargin'] || 'USD';

    if (this.cur1 === this.cur2) {
      null;
      return {
        convert: (n: number) => n,
        fromAccountToCurrency: 1,
        fromCurrencyToAccount: 1,
      } as any;
    }

    this.digits = Number(
      sourceSymbol.data[('Currency' + cur + 'Digits') as 'CurrencyMarginDigits']
    );
    this.symbol = symbolStore.findByCurrencies(this.cur1, this.cur2, true) as SymbolInfo;
    if (!this.symbol) {
      console.error('couldnot get conversion rate for ' + this.cur1 + ' to ' + this.cur2);
    }
    this.flip =
      this.symbol &&
      'data' in this.symbol &&
      String(this.symbol.data.CurrencyMargin).toUpperCase() === String(this.cur2).toUpperCase();
    makeAutoObservable(this, undefined, {deep: false});
  }

  // FROM ACCOUNT CURRENCY, TO MARGIN CURRENCY
  get fromAccountToCurrency() {
    const name = this.flip ? 'bid' : 'bid';
    if (!this.symbol?.tick?.[name]) {
      return 0;
    }
    const price = this.symbol ? this.symbol.tick[name] : 0;
    return this.flip ? 1 / price : price;
  }

  // FROM MARGIN CURRENCY, TO ACCOUNT CURRENCY
  get fromCurrencyToAccount() {
    const name = this.flip ? 'bid' : 'bid';
    if (!this.symbol?.tick?.[name]) {
      return 0;
    }
    const price = this.symbol ? this.symbol.tick[name] : 0;
    return this.flip ? price : 1 / price;
  }
}
